import React, { useState, useEffect } from 'react';
import '../styles.css';

const apiUrl = process.env.REACT_APP_API_URL;

function ArtistList({ search }) {
  const [artists, setArtists] = useState([]);
  const [selectedArtists, setSelectedArtists] = useState({});
  const [collapsedArtists, setCollapsedArtist] = useState({});


  useEffect(() => {
    async function fetchItems() {
      try {
        const response = await fetch(`${apiUrl}/artists`);
        const data = await response.json();
        setArtists(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchItems();
  }, []);

  const handleArtistTitleClick = (artist) => {
    setCollapsedArtist((prevCollapsedArtists) => ({
      ...prevCollapsedArtists,
      [artist.id]: !prevCollapsedArtists[artist.id],
    }));
  };

  const handleArtistClick = (artist) => {
    setSelectedArtists((prevSelectedArtists) => {
      return { ...prevSelectedArtists, [artist.id]: !prevSelectedArtists[artist.id] };
      // if (prevSelectedArtists?.id === artist.id) {
      //   return { ...artist, open: !prevSelectedArtists?.open };
      // }
      // return { ...artist, open: true };
    });
  };

  const filterArtists = (artist) => {
    // Check if event title matches the search term
    const nameMatch = artist.name.toLowerCase().includes(search.toLowerCase());

    // Return true if either title or artist name matches the search term
    return nameMatch;
  };

  return (
    <ul className='list'>
      {artists.length ? artists.filter((artist) => filterArtists(artist)).map((artist, i) => {
        return (
          <div>
            <div>
              { (
                <div className='day-container'>
                  <div className='day' onClick={() => handleArtistTitleClick(artist)}>{artist.name}</div>
                  <span className={`toggle-icon-artist day ${collapsedArtists[artist.id] === false ? '' : 'collapsed'}`} />
                </div>
              )}
            </div>
            {collapsedArtists[artist.id] && (<li key={artist.id} className='list-item'>
              { selectedArtists.playlist && (
                <div className='artist-detail'>
                  <iframe
                    className='soundcloud-player'
                    width="100%"
                    height="450"
                    scrolling="no"
                    frameborder="no"
                    allow="autoplay"
                    src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/${artist.playlist}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}>
                  </iframe>
                </div>
              )}
            </li>)}
          </div>
        )
      }) : <div className="loader-container"><div className="loader"></div></div>
      }
    </ul>
  );
}

export default ArtistList;
