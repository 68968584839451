import React, { useState, useEffect } from 'react';
import '../styles.css';
import share from '../resources/share.png';

const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(s => s.toUpperCase());
const apiUrl = process.env.REACT_APP_API_URL;

function EventList({ search, handleNotification, focus }) {
  const [events, setEvents] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [collapsedDays, setCollapsedDays] = useState(weekday.reduce((acc, day) => ({ ...acc, [day]: true }), {}));

  useEffect(() => {
    async function fetchItems() {
      const fetchEvents = async () => {
        const response = await fetch(`${apiUrl}/events`);
        const data = await response.json();
        return data.map(event => {
          const date = new Date(event.date._seconds * 1000);
          const dateString = date.toLocaleDateString('en-US');
          const day = weekday[date.getDay()];
          const dayTitle = `${weekday[date.getDay()]} - ${dateString}`

          return { ...event, dayTitle, day, dateString }
        });
      }
      try {
        let cacheDate;
        let data;
        const jsonString = localStorage.getItem("cacheDate");
        if (jsonString) cacheDate = JSON.parse(jsonString);
        if (!cacheDate) {
          fetchEvents();
          data = await fetchEvents();
          const today = new Date();
          localStorage.setItem("cacheDate", JSON.stringify(today.toLocaleDateString()));
          localStorage.setItem("cacheData", JSON.stringify(data));
          setEvents(data);
        } else {
          const today = new Date();
          if (today.toLocaleDateString() === cacheDate) {
            data = JSON.parse(localStorage.getItem('cacheData'));
            setEvents(data);
          } else {
            data = await fetchEvents();
            localStorage.setItem("cacheData", JSON.stringify(data));
            localStorage.setItem("cacheDate", JSON.stringify(today.toLocaleDateString()));
            setEvents(data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchItems();
  }, []);

  useEffect(() => {
    // Check if the events array has been populated
    if (events.length > 0) {
      // Find the <li> element with the matching event ID
      const liElement = document.getElementById(focus);
      if (liElement) {
        liElement.focus();
      }
    }
  }, [events]);

  const handleTimeClick = (dayOfWeek) => {
    setCollapsedDays((prevCollapsedDays) => ({
      ...prevCollapsedDays,
      [dayOfWeek]: !prevCollapsedDays[dayOfWeek],
    }));
  };

  const handleArtistClick = (artist) => {
    setSelectedArtist((prevSelectedArtist) => {
      if (prevSelectedArtist?.id === artist.id) {
        return { ...artist, open: !prevSelectedArtist?.open };
      }
      return { ...artist, open: true };
    });
  };

  const filterEvents = (event) => {
    // Check if event title matches the search term
    const titleMatch = event.title.toLowerCase().includes(search.toLowerCase());

    // Check if any artist name matches the search term
    const artistMatch = event.artists.some(artist =>
      artist.name.toLowerCase().includes(search.toLowerCase())
    );

    const venueMatch = event.venue.toLowerCase().includes(search.toLowerCase())
    const dayMatch = event.day.toLowerCase().includes(search.toLowerCase())


    // Return true if either title or artist name matches the search term
    const match = titleMatch || artistMatch || venueMatch || dayMatch;
    return match;
  };

  return (
    <ul className='list'>
      {events.length ? events.filter((event) => filterEvents(event)).map((event, i, filteredEvents) => {

        const hasDay = (filteredEvents[i - 1] === undefined) || (filteredEvents[i - 1]).dayTitle !== event.dayTitle ?
          true : false;
        return (
          <div>
            <div>
              {hasDay && (
                <div className='day-container'>
                  <div className='day' onClick={() => handleTimeClick(event.day)}>{event.dayTitle}</div>
                  <span className={`toggle-icon day ${collapsedDays[event.day] ? '' : 'collapsed'}`} />
                </div>
              )}
            </div>
            {collapsedDays[event.day] && (<li
              key={event.id}
              className='list-item'
              id={event.id}
              tabIndex="0"
            >
              <div className="venue-container">
                <h4 className='venue'>{event.venue} - {event.dateString}</h4>
                <img src={share} onClick={() => { navigator.clipboard.writeText(`raster.world/event/${event.id}`); handleNotification('copied'); }} alt="share event" className="share-icon" />
              </div>
              <h4 className='title'>Playing - {event.title}</h4>
              <div className='artists'>
                <span className='artists'>DJs:</span>
                {event.artists.map((artist, i) => (
                  <div className='artist-container'>
                    <span className='artist-name' onClick={() => handleArtistClick({ ...artist, eventId: event.id })}>• {artist.name}</span>
                    {artist.playlist && <span className='artist-playlist' onClick={() => handleArtistClick({ ...artist, eventId: event.id })}>Playlist</span>}
                    {selectedArtist?.name === artist.name && selectedArtist?.open && selectedArtist.playlist && selectedArtist.eventId === event.id && (
                      <div className='artist-detail'>
                        {selectedArtist.name}
                        <iframe
                          className='soundcloud-player'
                          width="100%"
                          height="450"
                          scrolling="no"
                          frameborder="no"
                          allow="autoplay"
                          src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/${selectedArtist.playlist}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}>
                        </iframe>
                      </div>
                    )}
                  </div>
                ))}

              </div>
              <a className='tickets-link' target="_blank" rel="noopener noreferrer" href={`http://${event.host}${event.uri}`}>
                <h4 className='tickets'>Tickets</h4>
              </a>
            </li>)}
          </div>
        )
      }) : <div className="loader-container"><div className="loader"></div></div>
      }
    </ul>
  );
}

export default EventList;
