import React, { useState, useEffect } from 'react';
import './styles.css';
import ArtistList from './components/ArtistList';
import EventList from './components/EventList';
import Toast from './components/Toast';


const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(s => s.toUpperCase());
const apiUrl = process.env.REACT_APP_API_URL;

function App() {
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState('events');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastDuration, setToastDuration] = useState(3000);

  // Parse the URL to get the event ID
  const path = window.location.pathname;
  const parts = path.split('/');
  let eventId = null;

  if (parts[1] === 'event' && parts[2]) {
    eventId = parts[2];
  }

  const handleNotification = (message, duration) => {
    setToastMessage(message);
    setToastDuration(duration);
    setShowToast(true);
  };

  const handleTab = (tab) => {
    setTab(tab);
  };

  return (
    <div className='outer'>
      {showToast && (
        <Toast className='toast'
          message={toastMessage}
          duration={toastDuration}
          onClose={() => setShowToast(false)}
        />
      )}
      <div className='container'>
        <div className='headerContainer'>
          <div className="eventsHeader">
            <h1 className='header' onClick={() => handleTab('events')}>Events</h1>
          </div>
          <div className="artistsHeader">
            <h1 className='header' onClick={() => handleTab('artists')}>Artists</h1>
          </div>
        </div>
        <div className="search">
          <input
            type="text"
            className="searchBar"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search"
          />
        </div>
        {tab === 'events' ? <EventList search={search} focus={eventId} handleNotification={handleNotification} /> : <ArtistList search={search} />}
      </div>
    </div>
  );
}

export default App;
